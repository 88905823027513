import {
  Collapse,
  TableCell,
  TableRow,
  CircularProgress,
  Checkbox,
  TextField,
  Box,
  Stack,
  Chip,
  FormControl,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdLocationOn, MdTimer, MdWarning } from 'react-icons/md';
import Button from 'components/Button';
import ErrorSnackbar from 'components/ErrorSnackbar';
import BankDepositDetailsDialog from 'components/ExpandableTable/BankDepositDetailsDialog';
import EditHistoryRowDetail from 'components/ExpandableTable/EditHistoryRowDetail';
import PriceTableRowDetail from 'components/ExpandableTable/PriceTableRowDetail';
import ReportTableRowDetail from 'components/ExpandableTable/ReportTableRowDetail';
import ShipmentRowDetail from 'components/ExpandableTable/ShipmentRowDetail';
import SubTable from 'components/ExpandableTable/SubTable';
import { FormDialogState } from 'components/FormDialog';
import { Hide } from 'components/Hide';
import IconButton from 'components/IconButton';
import LazyFormDialog from 'components/LazyFormDialog';
import { QualityChipVariant, QualityChips } from 'components/QualityChip';
import TableCellImage from 'components/TableCellImage';
import { defaultFormDialogState } from 'components/default-form-dialog-state';
import { BaseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import useUser from 'hooks/useUser';
import { mergeSx } from 'utils/merge-sx';
import {
  ExpandableTableRow as ExpandableTableRowType,
  RowDataType,
  Shipment,
  TableCell as TableCellData,
  TableCellType,
  TableType,
  Action,
  Button as ButtonData,
} from 'generated/graphql';
import ActionList from '../ActionList';
import NumberFormatRands from '../NumberFormatRands';
import ProductIcons from '../ProductIcons';
import TableDialog from '../TableDialog';
import ExpandableActionCell from './ExpandableActionCell';
import ExpandableCellStyle from './ExpandableCellStyle';
import ExpandableTableCell from './ExpandableTableCell';

export interface TableRowData extends ExpandableTableRowType {
  data?: any;
}

export interface TableRowGroup {
  rows: TableRowData[];
  total: string;
  totals?: TableCellData[];
}

enum ReservedButtonValue {
  Warning = 'Warning',
  Pending = 'Pending',
}

const ExpandableTableRow = ({
  data: { cells },
  nestedData,
  isNested = false, // indicates if the row is nested inside of another row
  type,
  refresh,
  hideLastBorder = false,
  data,
  isFetching = false,
  showCheckbox = false,
  checked = false,
  highlighted = false,
  initExpanded = false,
  onCheckboxToggle,
  onFieldChange,
  hasPending,
  onCustomSchemeRequest = () => {},
  setShowGraphs = () => {},
}: {
  isFetching?: boolean;
  hasPending?: boolean;
  data: TableRowData;
  nestedData?: TableRowData[];
  isNested?: boolean;
  type: TableType;
  refresh: () => void;
  hideLastBorder?: boolean;
  highlighted?: boolean;
  showCheckbox?: boolean;
  checked?: boolean;
  initExpanded?: boolean;
  onCheckboxToggle?(newValue: boolean): void;
  onFieldChange?(cellIndex: number, newValue: string): void;
  onCustomSchemeRequest?(url: string): void;
  setShowGraphs?(showGraphs: boolean): void;
}) => {
  const [open, setOpen] = useState(false);

  const [buttonActionsOpen, setButtonActionsOpen] = useState<ButtonData | undefined>(undefined);
  const [actionsLoading, setActionsLoading] = useState(new Array(cells.length).fill(false));
  const [actionsIndex, setActionsIndex] = useState<number>();
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const { isSeller } = useUser();
  const isStatisticalType = type === TableType.Statistics;
  const isPriceTableRowDetail = isSeller && isStatisticalType && data.dataType === RowDataType.Rows;

  const { isEnabled } = useFeatures();
  const isDispatchRedesignEnabled = isEnabled('DispatchRedesign');

  const isReportingType = type === TableType.Reporting;
  const isReportTableRowDetail = isReportingType && data.dataType === RowDataType.Report;
  const isBasicType = isDispatchRedesignEnabled && type === TableType.Basic;

  const { handleSchemeOrQuery, errorMessage, isLoading, paymentDetails, tableDialog, setErrorMessage } =
    useHandleCustomSchemeAndQuery(refresh, onCustomSchemeRequest);
  const clearError = () => setErrorMessage(undefined);

  useEffect(() => {
    if (actionsIndex && actionsLoading[actionsIndex] !== isLoading) {
      const actionsLoadingCopy = [...actionsLoading];
      actionsLoadingCopy[actionsIndex] = isLoading;
      setActionsLoading(actionsLoadingCopy);

      if (!isLoading) {
        setActionsIndex(undefined);
      }
    }
  }, [isLoading, actionsIndex, actionsLoading]);

  useEffect(() => {
    setOpen(initExpanded || false);
  }, [initExpanded]);

  const isExpandable = data.dataType !== RowDataType.None;

  const handleRowClick = () => {
    if (!isExpandable) {
      return;
    }
    setOpen((value) => !value);
  };

  return (
    <>
      <TableRow
        sx={(theme) => ({
          cursor: 'pointer',
          margin: theme.spacing(1, 0),
          ...(highlighted && { background: theme.palette.tertiary?.main }),
          ...(type === TableType.Detail || isBasicType
            ? { background: theme.palette.background.paper }
            : isReportingType
              ? { background: theme.palette.tertiary.main }
              : {
                  background: theme.palette.tertiary?.light,
                  [theme.breakpoints.down('md')]: {
                    background: theme.palette.tertiary?.main,
                  },
                }),
        })}
        onClick={handleRowClick}
      >
        {cells.map((cellData, i) => {
          const hideLeftBorder = i === 0;
          const hideRightBorder = i > 0;
          const shiftRight = isNested && i === 0;
          const isInline = !isNested && i === 0;

          let badgeClass = '';
          let cell;
          if (cellData.type === TableCellType.Action) {
            cell = (
              <ExpandableActionCell
                key={+i}
                sx={(theme: BaseTheme) => ExpandableCellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <Stack direction="row" justifyContent="flex-end">
                  {cellData.buttons.map((button, j) => {
                    return (
                      <div key={j}>
                        {button.value === ReservedButtonValue.Warning && (
                          <IconButton
                            size="small"
                            sx={(theme) => ({
                              color: theme.palette.warning.main,
                              fontSize: '1.3rem',
                              height: theme.spacing(1.5),
                            })}
                          >
                            <MdWarning />
                          </IconButton>
                        )}
                        {button.value === ReservedButtonValue.Pending && (
                          <IconButton
                            size="small"
                            sx={(theme) => ({ color: theme.palette.warning.main, fontSize: '1.3rem' })}
                          >
                            <MdTimer />
                          </IconButton>
                        )}
                        {(button.value || button.actions[0].value) &&
                          button.value !== ReservedButtonValue.Warning &&
                          button.value !== ReservedButtonValue.Pending && (
                            <Box sx={(theme) => ({ position: 'relative', margin: theme.spacing(0, 0, 0, 1.5) })}>
                              <>
                                <Button
                                  size="small"
                                  variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
                                  color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                  disabled={actionsLoading[i] || cellData.disabled}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (button.actions.length > 1) {
                                      setButtonActionsOpen(button);
                                      return;
                                    }
                                    const url = button.actions[0]?.url ?? '';
                                    const query = button.actions[0]?.query ?? '';

                                    setActionsIndex(i);
                                    handleSchemeOrQuery(url, query, {
                                      setFormDialog: setFormDialog,
                                      onExpandRow: () => isExpandable && setOpen(true),
                                    });
                                  }}
                                >
                                  {button.value || button.actions[0].value}
                                </Button>
                                {actionsLoading[i] && (
                                  <CircularProgress
                                    size={24}
                                    color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                    sx={(theme) => ({
                                      position: 'absolute',
                                      top: '50%',
                                      marginTop: theme.spacing(-1.5),
                                      marginLeft: theme.spacing(-4),
                                    })}
                                  />
                                )}
                              </>
                            </Box>
                          )}
                      </div>
                    );
                  })}
                </Stack>
              </ExpandableActionCell>
            );
          } else if (cellData.type === TableCellType.Input) {
            cell = (
              <ExpandableTableCell
                key={+i}
                sx={(theme: BaseTheme) => ExpandableCellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <TextField
                  variant={cellData.disabled ? 'standard' : 'outlined'}
                  margin="dense"
                  fullWidth
                  disabled={hasPending || cellData.disabled}
                  sx={(theme) => ({
                    '& div': {
                      background: cellData.disabled ? 'inherit' : theme.palette.common.white,
                    },
                    '& input': {
                      padding: theme.spacing(1, 1.75),
                    },
                    minWidth: 82,
                    maxWidth: 120,
                    margin: theme.spacing(0, 0.5),
                  })}
                  type="number"
                  label=""
                  value={cellData.value}
                  onChange={(event) => {
                    if (onFieldChange) onFieldChange(i, event.target.value);
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  InputProps={{
                    disableUnderline: cellData.disabled,
                  }}
                  onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
                />
              </ExpandableTableCell>
            );
          } else if (cellData.type === TableCellType.InputCurrency) {
            cell = (
              <ExpandableTableCell
                key={+i}
                sx={(theme: BaseTheme) => ExpandableCellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <TextField
                  variant={cellData.disabled ? 'standard' : 'outlined'}
                  disabled={hasPending || cellData.disabled}
                  margin="dense"
                  fullWidth
                  sx={(theme) => ({
                    '& div': {
                      background: cellData.disabled ? 'inherit' : theme.palette.common.white,
                    },
                    '& input': {
                      padding: theme.spacing(1, 1.75),
                    },
                    minWidth: 82,
                    maxWidth: 120,
                    margin: theme.spacing(0, 0.5),
                  })}
                  label=""
                  value={cellData.value}
                  onChange={(event) => {
                    if (onFieldChange) onFieldChange(i, event.target.value);
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  InputProps={{
                    inputComponent: NumberFormatRands as any,
                    disableUnderline: cellData.disabled,
                  }}
                />
              </ExpandableTableCell>
            );
          } else if (cellData.type === TableCellType.Icon) {
            cell = (
              <ExpandableTableCell
                key={+i}
                sx={mergeSx(
                  (theme: BaseTheme) => ExpandableCellStyle({ theme, cellData, type, hideLastBorder, index: i }),
                  {
                    minWidth: 84,
                  },
                )}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <Stack
                  direction="row"
                  sx={(theme) => ({
                    justifyContent: cellData.align.toLowerCase() as 'left' | 'right',
                    paddingRight: theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                      paddingX: theme.spacing(1),
                    },
                  })}
                >
                  <ProductIcons value={cellData.image} cases={cellData.value} />
                </Stack>
              </ExpandableTableCell>
            );
          } else if (cellData.type === TableCellType.Chip) {
            cell = (
              <ExpandableTableCell
                key={+i}
                sx={mergeSx(
                  (theme: BaseTheme) => ExpandableCellStyle({ theme, cellData, type, hideLastBorder, index: i }),
                  {
                    minWidth: 84,
                  },
                )}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <Stack
                  direction="row"
                  sx={(theme) => ({
                    justifyContent: cellData.align.toLowerCase() as 'left' | 'right',
                    paddingRight: theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                      paddingX: theme.spacing(1),
                    },
                  })}
                >
                  <QualityChips chips={cellData.buttons} variant={QualityChipVariant.Stacked} />
                </Stack>
              </ExpandableTableCell>
            );
          } else if (isDispatchRedesignEnabled && cellData.type === TableCellType.Image) {
            cell = (
              <ExpandableTableCell
                key={+i}
                sx={mergeSx(
                  (theme: BaseTheme) => ExpandableCellStyle({ theme, cellData, type, hideLastBorder, index: i }),
                  {
                    minWidth: 84,
                  },
                )}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <TableCellImage data={cellData} />
              </ExpandableTableCell>
            );
          } else if (cellData.type === TableCellType.InputSelect) {
            cell = (
              <ExpandableTableCell
                key={+i}
                sx={(theme: BaseTheme) => ExpandableCellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <FormControl
                  sx={(theme) => ({
                    '& div': {
                      background: theme.palette.common.white,
                    },
                    minWidth: 82,
                    maxWidth: 120,
                    margin: theme.spacing(0, 0.5),
                  })}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                >
                  <Select
                    native
                    displayEmpty
                    disabled={hasPending || cellData.disabled}
                    value={cellData.value}
                    onChange={(event) => {
                      if (onFieldChange) onFieldChange(i, event.target.value);
                    }}
                  >
                    <option value="0" aria-label="None">
                      None
                    </option>
                    {cellData?.options?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </ExpandableTableCell>
            );
          } else {
            cell = (
              <ExpandableTableCell
                key={+i}
                sx={(theme: BaseTheme) =>
                  ExpandableCellStyle({
                    theme,
                    cellData,
                    type,
                    hideLastBorder,
                    isNested,
                    shiftRight,
                    hideLeftBorder,
                    hideRightBorder,
                    isInline,
                    index: i,
                  })
                }
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                {i === 0 &&
                  isExpandable &&
                  (isStatisticalType || isReportingType ? (
                    <IconButton size="small" sx={{ padding: 0, marginRight: 1 }}>
                      {open ? <MdKeyboardArrowUp size={16} /> : <MdKeyboardArrowDown size={16} />}
                    </IconButton>
                  ) : (
                    <Hide>
                      <IconButton size="small" sx={{ padding: 0 }}>
                        {open ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
                      </IconButton>
                    </Hide>
                  ))}
                {cellData.image !== '' && (
                  <Hide direction="up">
                    {/* TODO: Handle other icon types */}
                    <Box
                      sx={(theme) => ({ opacity: 0.6, marginTop: theme.spacing(-0.3), marginLeft: theme.spacing(-1) })}
                    >
                      <MdLocationOn />
                    </Box>
                  </Hide>
                )}
                <Hide direction="up">
                  {cellData.subtitle.length > 0 ? (
                    <span>
                      {cellData.value}
                      <br />
                      <Box
                        component="span"
                        sx={(theme) => ({
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.text.secondary,
                        })}
                      >
                        {cellData.subtitle}
                      </Box>
                    </span>
                  ) : (
                    <span className={badgeClass}>{cellData.value}</span>
                  )}
                </Hide>
                <Hide>
                  {cellData.subtitle.length > 0 ? (
                    <Box>
                      {cellData.value}
                      <Box
                        sx={(theme) => ({
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.text.secondary,
                        })}
                      >
                        {cellData.subtitle}
                      </Box>
                    </Box>
                  ) : (
                    <span className={badgeClass}>{cellData.value}</span>
                  )}
                </Hide>
                {isStatisticalType && cellData.tags.length > 0 && (
                  <Stack sx={(theme) => ({ display: 'flex', flexDirection: 'row', gap: theme.spacing(0.25) })}>
                    {cellData.tags.map((productTag, i) => {
                      if (productTag.label.length === 0) {
                        return null;
                      }
                      return (
                        <Chip
                          key={i}
                          label={productTag?.label}
                          sx={(theme) => ({
                            background: theme.palette.primary.light,
                            color: theme.palette.common.white,
                            fontSize: theme.typography.pxToRem(12),
                            width: 'max-content',
                            height: theme.spacing(2.5),
                          })}
                        />
                      );
                    })}
                  </Stack>
                )}
              </ExpandableTableCell>
            );
          }
          return cellData.mobile ? cell : isStatisticalType || isReportingType ? cell : <Hide key={+i}>{cell}</Hide>;
        })}
        {isStatisticalType && ( // Adds additional space after table columns for pricing dashboard sales table
          <ExpandableTableCell
            sx={(theme) => ({
              '&.MuiTableCell-root': {
                background: isNested ? theme.palette.common.white : theme.palette.tertiary.light,
                borderRight: 'hidden',
                maxWidth: theme.spacing(1),
                minWidth: theme.spacing(1),
              },
            })}
          />
        )}
        {showCheckbox && (
          <ExpandableTableCell>
            <Checkbox
              disabled={hasPending}
              color="primary"
              checked={checked}
              onChange={(event) => {
                if (onCheckboxToggle) onCheckboxToggle(event.target.checked);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </ExpandableTableCell>
        )}
      </TableRow>
      {open && isExpandable && (
        <>
          {data.dataType !== RowDataType.Rows && !isReportingType && (
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  ...(type === TableType.Detail
                    ? {
                        padding: theme.spacing(1),
                        borderBottom: 0,
                        overflow: 'hidden',
                        background: theme.palette.background.paper,
                      }
                    : {
                        padding: theme.spacing(1),
                        borderBottom: 0,
                        overflow: 'hidden',
                        background: theme.palette.background.paper,
                      }),
                })}
                colSpan={12} /* TODO: Set colSpan exactly */
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {data.dataType === RowDataType.Shipment && (
                    <ShipmentRowDetail
                      key={data.id}
                      data={data.data as Shipment}
                      refresh={refresh}
                      isFetching={isFetching}
                    />
                  )}
                  {data.dataType === RowDataType.SubTable && <SubTable data={data.data} onRefresh={refresh} />}
                </Collapse>
              </TableCell>
            </TableRow>
          )}
          {isPriceTableRowDetail && <PriceTableRowDetail data={data} setShowGraphs={setShowGraphs} />}
          {isReportTableRowDetail && (
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  padding: theme.spacing(1),
                  borderBottom: 0,
                  overflow: 'hidden',
                  background: theme.palette.background.paper,
                })}
                colSpan={12} /* TODO: Set colSpan exactly */
              >
                <ReportTableRowDetail data={data.data} />
              </TableCell>
            </TableRow>
          )}
          {nestedData?.map((row, k) => (
            <ExpandableTableRow
              key={k}
              isFetching={isFetching}
              hasPending={hasPending}
              data={row}
              isNested
              type={type}
              initExpanded={false}
              refresh={refresh}
              highlighted={false}
              onCustomSchemeRequest={onCustomSchemeRequest}
            />
          ))}
          {!isStatisticalType && data.dataType === RowDataType.Rows && (
            <EditHistoryRowDetail data={data} refresh={refresh} />
          )}
        </>
      )}
      {tableDialog.content && <TableDialog data={tableDialog.content} onClose={tableDialog.clear} />}
      <ErrorSnackbar isOpen={!!errorMessage} onClose={clearError}>
        {errorMessage!}
      </ErrorSnackbar>
      {buttonActionsOpen && (
        <ActionList
          open={!!buttonActionsOpen}
          button={buttonActionsOpen}
          onClose={(action?: Action) => {
            setButtonActionsOpen(undefined);
            if (!action) return;

            handleSchemeOrQuery(action.url, action.query, {
              setFormDialog: setFormDialog,
              onExpandRow: () => isExpandable && setOpen(true),
            });
          }}
        />
      )}
      {paymentDetails.open && (
        <BankDepositDetailsDialog shipmentId={paymentDetails.id} onClose={paymentDetails.onClose} />
      )}
      {formDialog.open && (
        <LazyFormDialog
          type={formDialog.type}
          editIDs={formDialog.editIDs}
          open={formDialog.open}
          onClose={() => {
            setFormDialog(defaultFormDialogState);
          }}
          onSubmit={() => {
            setFormDialog(defaultFormDialogState);
            refresh();
          }}
        />
      )}
    </>
  );
};

export default ExpandableTableRow;
