import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Hide } from 'components/Hide';
import ExpandableTableCell from './ExpandableTableCell';

export const ExpandableTablePlaceholder = () => {
  return (
    <TableContainer
      sx={(theme) => ({
        border: `1px solid ${theme.palette.tertiary?.main}`,
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(2),
      })}
    >
      <LinearProgress color="secondary" />
      <Table
        sx={(theme) => ({
          borderCollapse: 'separate',
          borderRadius: theme.spacing(1),
          [theme.breakpoints.down('md')]: {
            background: theme.palette.tertiary?.main,
          },
        })}
      >
        <Hide>
          <TableHead>
            <TableRow sx={(theme) => ({ overflow: 'none', background: theme.palette.background.paper })}>
              <TableCell sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.common.white}` })} />
            </TableRow>
          </TableHead>
        </Hide>
        <TableBody>
          {Array.from({ length: 10 }).map((_, index) => (
            <TableRow
              key={index}
              sx={(theme) => ({
                background: theme.palette.tertiary?.light,
                borderBottom: `1px solid ${theme.palette.common.white}`,
                height: '50px',
              })}
            >
              <ExpandableTableCell />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExpandableTablePlaceholder;
